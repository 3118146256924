@font-face {
  font-family: 'Aeonik-Regular';
  src: url('../assets/fonts/Aeonik-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mute Logo Font';
  src: url('../assets/fonts/Aeonik-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Poppins&family=Quattrocento&family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  font-family: 'Aeonik-Regular', sans-serif;
  overflow-x: hidden;
  --color-dark-blue: #090C16;
  --color-blue: #111524;
  --color-modal-blue: #111524;
  --color-accent-blue: #3294F8;
  --color-white: rgba(255,255,255,1);
  --color-gray: rgba(248,248,248,1);
}

body {
  position: relative;
  min-height: 100vh;
  background-image: linear-gradient(180deg, #090C16, #090C16);
  overflow-x: hidden;
}

button {
  padding: 0;
  cursor: pointer;
}

button,
textarea,
input {
  font-family: 'Aeonik-Regular', sans-serif;
}

.track-vertical {
  right: 2px;
  display: block !important;
  height: 100%;
  background: #101833;
  border-radius: 8px;
}

.thumb-vertical {
  right: 0;
  display: block !important;
  background: #00C0FF;
  border-radius: 8px;
}
